.mGalleryIntro {
    padding: 40px 320px;
}

.mGalleryIntro:after {
    content: "";
    display: block;
    clear: left;
}

.mGalleryIntro h1 {
    margin: 20px 0;
}

.mGalleryIntro nav {
    float: left;
    margin-left: -320px;
    margin-top: -40px;
}

.mGalleryIntro nav > div + div {
    margin-top: 20px;
}

.mGalleryIntro nav a {
    color: inherit;
}

@media all and (max-width: 1300px) {
    .mGalleryIntro {
        padding-right: 0;
    }
}

@media all and (max-width: 660px) {
    .mGalleryIntro {
        padding-left: 0;
    }

    .mGalleryIntro nav {
        float: none;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 40px;
    }
}
