.mCheckboxFilter {
    margin-top: 120px;
}

.mCheckboxFilter h1 {
    font-size: 24px;
    line-height: 26.4px;
    text-transform: uppercase;
}

.mCheckboxFilter div {
    margin: 20px 0;
}

@media all and (max-width: 980px) {
    .mCheckboxFilter {
        margin-top: 0;
    }
}
