.toc {
    width: 300px;
    float: left;
    margin-left: -320px;
}

.toc h2 {
    font-size: 19.2px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
}

.toc ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.toc li {
    margin: 14px 0;
}

.toc a {
    color: black;
    text-decoration: none;
}

.toc a:focus,
.toc a:hover {
    text-decoration: underline;
}

@media (max-width: 1300px) {
    .toc {
        width: auto;
        float: none;
        margin-left: 0;
    }
}
