.mWallCostResult,
.mWallCostResult thead,
.mWallCostResult tbody {
    display: block;
}

.mWallCostResult {
    max-width: 37em;
}

.mWallCostResult tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.mWallCostResult td,
.mWallCostResult th {
    display: block; /* Workaround for IE11 */
    width: 20%;
    flex: none;
    margin-right: 1px;
    margin-bottom: 1px;
}

.mWallCostResult th:first-child {
    flex: auto;
}

.mWallCostResult td:last-child {
    width: 100%;
    text-align: left;
}

.mWallCostResult button {
    background: transparent;
    position: relative;
    padding: 0 26px 0 0;
}

.mWallCostResult button:focus {
    outline: 1px dotted;
}

.mWallCostResult button:after {
    content: url(/resources/images/arrow_down.svg);
    line-height: 8px;
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(0deg);
}

.mWallCostResult button.expanded:after {
    transform: rotate(180deg);
}

@media all and (max-width: 660px) {
    .mWallCostResult tr {
        margin-bottom: 1px;
    }

    .mWallCostResult td,
    .mWallCostResult th {
        width: auto;
        margin-right: 0;
        text-align: left;
        margin-bottom: 0;
    }

    .mWallCostResult th:first-child {
        flex: none;
        width: 100%;
        margin-bottom: 0;
    }

    .mWallCostResult thead th {
        display: none;
    }

    .mWallCostResult td:nth-last-child(2) {
        flex: auto;
    }
}
