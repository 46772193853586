.mNavAction {
    font-size: 19.2px;
    font-weight: 500;
    border: none;
    padding: 0;
    text-decoration: none;
    color: black;
    background-color: transparent;
    background-image: inline(images/default_menu_icon.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    display: table-cell;
    vertical-align: middle;
    height: 40px;
    padding-left: 50px;
    position: relative;
}

.mNavAction:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: transparent;
}

.mNavAction:hover:before {
    background: rgba(255, 255, 255, 0.2);
}

/* Variants used in contexts with black/dark background */
.mGlobalHeader .mainItems .mNavAction,
.mHighlightedContent .mNavAction {
    color: white;
}

.mGlobalHeader .mainItems .mNavAction:hover,
.mGlobalHeader .mainItems .mNavAction:focus {
    color: var(--color-jordy-blue);
}

.mHighlightedContent .mNavAction:hover {
    /* This is a known WCAG violation, insisted on by the designers, at least
     * for now. Might hope for a different and better approach later
     */
    color: var(--color-jordy-blue);
}

.mGlobalHeader .mNavAction:focus-visible,
.mHighlightedContent .mNavAction:focus-visible {
    outline: 1px dotted;
}

/* Variant with centered text and icon on top */
.mHighlightedContent .mNavAction {
    display: inline-block;
    text-align: center;
    padding-left: 0;
    padding-top: 50px;
    background-position: top center;
}

.mHighlightedContent .mNavAction:before {
    left: 50%;
    margin-left: -20px;
}

/* Menu and search triggers (also match the above) */
.mNavAction.menuTrigger {
    background-image: inline(images/show_menu.svg);
}

.mNavAction.menuTrigger.expanded {
    background-image: inline(images/hide_menu.svg);
}

.mNavAction.searchTrigger {
    background-image: inline(images/search.svg);
}

@media all and (max-width: 660px) {
    .mGlobalHeader .mNavAction {
        display: block;
        height: auto;
        padding-left: 0;
        padding-top: 50px;
        background-position: top center;
        font-size: 14.4px;
        line-height: 16.8px;
        text-align: center;
        word-wrap: break-word;
    }

    .mGlobalHeader .mainItems .mNavAction {
        width: 100%;
    }
}
