.mRange {
    font-size: 0;
    letter-spacing: 0;
}

.mRange .valueExplanation {
    font-size: 19.2px;
    letter-spacing: 0.4px;
    color: var(--color-scorpion);
}

.mRange > input {
    font-size: 19.2px;
    letter-spacing: 0.4px;
    width: calc(100% - 5.5em - 31px);
    margin: 0.25em 0;
}

.mRange .info {
    font-size: 21.6px;
    letter-spacing: 0.4px;
    font-weight: 500;
    display: inline-block;
    width: 4.5em;
    border: 1px solid;
    vertical-align: top;
    padding: 0.2em;
    text-align: center;
}

.mRange .helpButton {
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
}
