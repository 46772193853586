.mSearchForm {
    position: relative;
    padding-right: 53px;
    margin-bottom: 40px;
}

.mSearchForm input {
    width: 100%;
    height: 53px;
    border: none;
    padding-left: 20px;
    background: var(--color-concrete);
}

.mSearchForm button {
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
}

.mSearchForm img {
    width: 53px;
    height: 53px;
    vertical-align: top;
}
