*,
*:before,
*:after,
input[type=search] { /* override normalize.css */
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background: white;
    color: black;
    font-family: "Brandon Grotesque", sans-serif;
    font-weight: 400;
    font-size: 19.2px;
    line-height: 24px;
    letter-spacing: 0.4px;
}

h1, .h1 {
    font-weight: 500;
    font-size: 60px;
    line-height: 69.6px;
    text-transform: none;
    letter-spacing: 1px;
    margin: 1.5rem 0;
}

h2 {
    font-weight: 700;
    font-size: 26.4px;
    line-height: 31.2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 34px 0 14px;
}

h3, .h3 {
    font-weight: 400;
    font-size: 26.4px;
    line-height: 31.2px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 14px 0 2px;
}

h4, .h4 {
    font-weight: 700;
    font-size: 19.2px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 14px 0 2px;
}

p, dl {
    margin: 0 0 14px;
}

a {
    color: #5e5e5e;
    cursor: pointer;
}

b, strong {
    font-weight: 500;
}

figure {
    margin: 28px 0;
}

table {
    border-collapse: separate;
    border-spacing: 1px;
}

td, th {
    padding: 5px;
    text-align: center;
    background-color: var(--color-catskill-white);
    font-weight: 400;
}

tbody th {
    text-align: left;
    font-weight: 700;
}

thead th {
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
}

fieldset {
    padding: 0;
    border: none;
    margin: 0;
}

legend, label {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}

legend {
    margin: 0 0 14px;
}

select, button {
    background: var(--color-alto);
    color: black;
    border: none;
    padding-top: 2px;
    padding-bottom: 3px;
}

select {
    font-weight: 500;
    text-transform: uppercase;
}

select:disabled {
    background: var(--color-concrete);
}

.visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

input[type=text],
input[type=number] {
    border: none;
    border-bottom: 1px solid black;
    padding: 2px 2px 2px 5px;
    background: var(--color-catskill-white);
    font-weight: 400;
}

input[type=range] {
    padding: 0;
}

/* Start range input styling.
 * HACK - these styles are all nonstandard and in an ideal world wouldn't ever
 * be used in production. The non-prefixed 'appearance' property is
 * intentionally omitted, so that a standards-compliant browser wouldn't end
 * up with invisible range inputs.
 */
input[type=range] {
    -webkit-appearance: none;
}

input[type=range]::-moz-range-track {
    background-color: black;
    height: 1px;
}

input[type=range]::-moz-range-thumb {
    background-color: var(--color-denim);
    border-color: var(--color-denim);
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

input[type=range]::-webkit-slider-runnable-track {
    background: linear-gradient(to bottom, white 10px, black 10px, black 11px, white 11px);
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: var(--color-denim);
    border-color: var(--color-denim);
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

input[type=range]::-ms-track {
    height: 1px;
    background-color: transparent;
    border-color: transparent;
    border-width: 10px 0;
    color: transparent;
    background: linear-gradient(to bottom, white 10px, black 10px, black 11px, white 11px);
}

input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
    background: black;
}

input[type=range]::-ms-thumb {
    background-color: var(--color-denim);
    border-color: var(--color-denim);
    border-radius: 10px;
    width: 20px;
    height: 20px;
}

/* End range input styling */

@media all and (max-width: 980px) {
    h1, .h1 {
        font-size: 40.8px;
        line-height: 48px;
    }
}
