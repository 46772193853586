.mImageRow {
    margin: 14px -10px;
    padding: 0;
    list-style: none;
    font-size: 0;
    letter-spacing: 0;
}

.mImageRow > li {
    font-size: 19.2px;
    letter-spacing: 0.4px;
    word-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 10px;
}

.mImageRow.hasThree > li {
    width: 33.33%;
}

.mImageRow img {
    max-width: 100%;
}

.mImageRow figcaption {
    margin-top: 5px;
}

@media all and (max-width: 660px) {
    .mImageRow > li,
    .mImageRow.hasThree > li {
        width: auto;
    }
}
