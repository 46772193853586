/* Contains HelpButton, WallProperties and WallProducts */
.mWallSelectorResult:after {
    content: "";
    display: block;
    clear: left;
}

.mWallSelectorResult .refine {
    font-size: 26.4px;
    line-height: 31.2px;
    color: black;
    text-decoration: none;
    display: block;
    margin-top: 40px;
}

.mWallSelectorResult .refine:focus,
.mWallSelectorResult .refine:hover {
    text-decoration: underline;
}

.mWallSelectorResult .refine:before {
    content: "‹";
    font-size: 31.2px;
    vertical-align: bottom;
    margin-right: 5px;
    display: inline-block; /* stop underline */
}

.mWallSelectorResult .result {
    padding: 40px 0 120px;
    margin-top: 20px;
}

.mWallSelectorResult .selectedType {
    margin-bottom: 40px;
    text-align: center;
}

.mWallSelectorResult .wallSorting {
    font-weight: bold;
    font-size: 26.4px;
    line-height: 31.2px;
    margin-bottom: 60px;
}

.mWallSelectorResult .explanations {
    margin-bottom: 40px;
    margin-left: 80px;
    color: var(--color-denim);
}

.mWallSelectorResult .explanations dl:after {
    content: "";
    display: block;
    clear: left;
}

.mWallSelectorResult .explanations dt,
.mWallSelectorResult .explanations dd {
    float: left;
    margin: 0;
}

.mWallSelectorResult .explanations dt {
    font-weight: 700;
    clear: left;
    min-width: 3em;
}

.mWallSelectorResult .explanations dd {
    padding-left: 20px;
    width: calc(100% - 3em);
}

.mWallSelectorResult .explanations dd:before {
    content: "=";
    margin-left: -20px;
    margin-right: 10px;
}

.mWallSelectorResult .resultHeading {
    font-weight: 400;
    margin-top: 20px;
}

.mWallSelectorResult .boardIcon {
    width: 40px;
    height: 40px;
    vertical-align: -10px;
    margin-right: 5px;
    display: inline-block; /* stop underline */
}

.mWallSelectorResult .resultToggle {
    background: transparent;
    text-decoration: underline;
    position: relative;
    padding: 0 1em 0 0;
    margin-right: 36px;
}

.mWallSelectorResult .resultToggle:focus {
    outline: 1px dotted;
}


.mWallSelectorResult .resultToggle.expanded {
    text-decoration: none;
}

.mWallSelectorResult .resultToggle:after {
    content: url(/resources/images/arrow_down.svg);
    line-height: 8px;
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(0deg);
}

.mWallSelectorResult .resultToggle.expanded:after {
    transform: rotate(180deg);
}

@media all and (max-width: 660px) {
    .mWallSelectorResult .explanations {
        margin-left: 0;
    }

    .mWallSelectorResult .resultHeading {
        padding-left: 30px;
        position: relative;
    }

    .mWallSelectorResult .boardIcon {
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        margin-right: 0;
    }
}
