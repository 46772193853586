.mSystemSelectorList {
    padding: 0;
    margin: 0;
}

.mSystemSelectorList > li {
    display: block;
    padding: 30px 0;
    border-bottom: 2px solid black;
}

.mSystemSelectorList > li:first-child {
    border-top: 2px solid black;
}

.mSystemSelectorList a {
    color: inherit;
    text-decoration: none;
}

.mSystemSelectorList a:hover,
.mSystemSelectorList a:focus {
    text-decoration: underline;
}

.mSystemSelectorList a:after {
    content: " ›";
}

.mSystemSelectorList .description {
    margin-top: 10px;
    padding-left: 1.1em;
    list-style-type: disc;
}
