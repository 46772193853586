.mRelatedProducts {
    padding-bottom: 40px;
}

.mRelatedProducts ul {
    padding: 0;
    margin: 0 -10px;
    font-size: 0;
    letter-spacing: 0;
}

.mRelatedProducts li {
    font-size: 19.2px;
    letter-spacing: 0.4px;
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin: 0 10px 60px;
}

.mRelatedProducts a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
}

.mRelatedProducts a:hover,
.mRelatedProducts a:focus {
    text-decoration: underline;
}

.mRelatedProducts a:after {
    content: "\a0>";
}

.mRelatedProducts figure {
    margin: 16px 0;
    width: 140px;
}

.mRelatedProducts img {
    max-width: 100%;
    vertical-align: top;
}

@media all and (max-width: 660px) {
    .mRelatedProducts ul,
    .mRelatedProducts li {
        margin-left: 0;
        margin-right: 0;
    }
}
