.mLinkList {
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.mLinkList > li {
    display: block;
}

.mLinkList a {
    color: black;
    text-decoration: none;
}

.mLinkList a:hover,
.mLinkList a:focus {
    text-decoration: underline;
}

.mLinkList a:after {
    content: ">";
}

.mProductList .mLinkList {
    float: left;
    width: 300px;
}

@media all and (max-width: 660px) {
    .mProductList .mLinkList {
        width: 100%;
    }

    .mProductList .mLinkList {
        margin-top: 5px;
    }
}
