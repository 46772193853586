.crisisBox {
    font-weight: 700;
    font-size: 21.6px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    color: white;
    background: var(--color-well-read);
    padding: 10px 20px;
    margin: 0;
}
