.mHelpButton {
    padding: 2px;
    width: 26px;
    height: 26px;
    background: transparent;
    vertical-align: -2px;
}

.mHelpButton:focus {
    outline: 1px dotted;
}

.mWallSelectorResult .mHelpButton {
    margin-left: -36px;
}
