/* Contains Button, Range */
.mWallAmountCalculator {
    margin: 40px 0;
}

.mWallAmountCalculator .group {
    margin: 30px 0;
    max-width: 30em;
}

.mWallAmountCalculator label {
    text-transform: none;
}

.mWallAmountCalculator input[type=number] {
    width: 5em;
}
