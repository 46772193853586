.mProductRelatedSystems {
    border-bottom: 2px solid black;
    margin-bottom: 40px;
}

.mProductRelatedSystems ul {
    margin: 30px 0 0;
    padding: 0;
}

.mProductRelatedSystems ul:after {
    content: "";
    display: block;
    clear: left;
}

.mProductRelatedSystems li {
    display: block;
    float: left;
    width: 450px;
    margin-right: 20px;
    font-size: 24px;
    margin-bottom: 40px;
}

@media all and (max-width: 980px) {
    .mProductRelatedSystems li {
        width: 100%;
        margin-right: 0;
    }
}
