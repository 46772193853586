/* Contains LinkList */
.mProductList {
    padding-bottom: 40px;
    border-bottom: 2px solid black;
    margin-bottom: 40px;
}

.mProductList h2 {
    margin: 0 0 5px;
}

.mProductList .variantList {
    margin: 0;
    padding: 0;
}

.mProductList .variantList > li {
    display: block;
    margin-bottom: 20px;
}

.mProductList .variantList > li:after {
    content: "";
    display: block;
    clear: left;
}

.mProductList figure {
    margin: 0 20px 0 0;
    width: 300px;
    float: left;
    min-height: 1px;
}

.mProductList img {
    max-width: 100%;
    vertical-align: top;
}

.mProductList .variantText {
    float: left;
    width: 300px;
    margin-right: 20px;
}

.mProductList .variantText p {
    margin-bottom: 0;
}

.mProductList .variantName {
    font-weight: 700;
}

.mProductList .variantDescription {
    font-weight: 400;
    margin-top: 5px;
}

@media all and (max-width: 980px) {
    .mProductList figure {
        margin-bottom: 5px;
    }
}

@media all and (max-width: 660px) {
    .mProductList .variantList > li {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
    }

    .mProductList figure {
        width: 100%;
        float: none;
    }

    .mProductList .variantText {
        width: 100%;
    }
}
