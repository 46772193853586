.mGlobalFooter {
    background: var(--color-limed-spruce);
    color: white;
    padding: 30px 80px; /* Horizontal padding to leave room for chat bubble */
    text-align: center;
}

.mGlobalFooter a {
    color: white;
    text-decoration: none;
}

.mGlobalFooter a:focus,
.mGlobalFooter a:hover {
    text-decoration: underline;
}

.mGlobalFooter .social {
    margin: 30px 0;
    padding: 0;
    list-style: none;
    text-align: center;
    word-spacing: 60px;
}

.mGlobalFooter .social li {
    display: inline-block;
}

.mGlobalFooter .social a {
    display: inline-block;
    border-radius: 20px;
}

.mGlobalFooter .social a:hover {
    background: rgba(255, 255, 255, 0.2);
}

.mGlobalFooter .social img {
    width: 40px;
    height: 40px;
    vertical-align: bottom;
}

.mGlobalFooter .logo {
    height: 40px;
    vertical-align: bottom;
}

.mGlobalFooter address {
    font-weight: 500;
    font-style: normal;
    font-size: 16.8px;
    margin-top: 5px;
}

.mGlobalFooter .cookies {
    margin-top: 30px;
    font-weight: 500;
    font-size: 16.8px;
    text-transform: uppercase;
}

@media all and (max-width: 660px) {
    .mGlobalFooter {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 80px; /* Leave room for chat bubble */
    }
}
