.mBimFilter {
    margin-bottom: 60px;
    padding-right: 50px;
}

.mBimFilter legend {
    text-transform: none;
    letter-spacing: normal;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid;
}

.mBimFilter div {
    padding: 5px 0;
}

.mBimFilter label {
    font-weight: 500;
    text-transform: none;
    letter-spacing: normal;
}

@media all and (max-width: 980px) {
    .mBimFilter {
        float: left;
        width: 50%;
        margin-bottom: 0;
    }

    .mBimFilter + .mBimFilter {
        padding-left: 10px;
    }
}

@media all and (max-width: 660px) {
    .mBimFilter {
        float: none;
        width: 100%;
        margin-bottom: 20px;
    }

    .mBimFilter + .mBimFilter {
        padding-left: 0;
    }
}
