.mSelectorFilter h1 {
    font-size: 21.6px;
    text-transform: uppercase;
    margin: 100px 0 20px;
}

.mSelectorFilter fieldset {
    border-style: none;
    padding: 0;
    margin: 0;
}

@media all and (max-width: 980px) {
    .mSelectorFilter h1 {
        margin-top: 0;
    }
}
