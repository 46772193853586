/* Contains NavAction */
.mGlobalHeader {
    background-color: var(--color-limed-spruce);
}

.mGlobalHeader .mainItems {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 20px;
}

.mGlobalHeader .logo {
    float: left;
    width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.mGlobalHeader .logo a {
    text-decoration: none;
}

.mGlobalHeader .logo img {
    height: 40px;
    vertical-align: bottom;
}

.mGlobalHeader ul {
    margin: 0;
    padding: 0;
    font-size: 0;
}

.mGlobalHeader li {
    font-size: 19.2px;
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.mGlobalHeader .menuTriggerContainer[hidden] {
    display: none;
}

.mGlobalHeader .searchForm {
    display: table;
    width: 100%;
    margin: 5px 0;
}

.mGlobalHeader .searchForm div {
    display: table-cell;
}

.mGlobalHeader .searchForm input,
.mGlobalHeader .searchForm button {
    border: none;
    color: white;
    vertical-align: top;
}

.mGlobalHeader .searchForm input {
    background: var(--color-shark);
    height: 30px;
    padding-left: 8px;
    width: 100%;
}

.mGlobalHeader .searchForm button {
    padding: 0;
    display: table-cell;
}

.mGlobalHeader .searchForm button img {
    vertical-align: top;
    width: 30px;
    height: 30px;
}

.mGlobalHeader .menu {
    padding-top: 52px;
    padding-bottom: 35px;
    background: var(--color-ebb);
}

.mGlobalHeader .menuItems {
    max-width: 1300px;
    margin: 0 auto;
}

.mGlobalHeader .menu .searchForm {
    display: none;
    margin-top: 0;
    margin-bottom: 40px;
}

.mGlobalHeader .menu .mobileContact {
    display: none;
}

@media all and (max-width: 1300px) {
    .mGlobalHeader .mainItems,
    .mGlobalHeader .menuItems {
        max-width: 960px;
    }

    .mGlobalHeader .mainItems ul {
        clear: left;
    }
}

@media all and (max-width: 980px) {
    .mGlobalHeader .mainItems,
    .mGlobalHeader .menuItems {
        max-width: 660px;
    }

    .mGlobalHeader .mainItems ul {
        clear: none;
    }
}

@media all and (max-width: 660px) {
    .mGlobalHeader .mainItems,
    .mGlobalHeader .menuItems {
        max-width: none;
    }

    .mGlobalHeader .mainItems {
        padding-top: 20px;
        padding-bottom: 0;
        border-bottom-style: none;
    }

    .mGlobalHeader .mainItems:after {
        content: "";
        display: block;
        clear: both;
    }

    .mGlobalHeader .logo {
        width: 200px;
        margin-left: 10px;
    }

    .mGlobalHeader li {
        width: 86px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .mGlobalHeader .mainItems li {
        display: none;
    }

    .mGlobalHeader .mainItems .menuTriggerContainer {
        display: block;
        float: right;
        width: 64px;
    }

    .mGlobalHeader .menu {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .mGlobalHeader .menu .searchForm {
        display: table;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    }

    .mGlobalHeader .menuItems {
        padding-top: 20px;
        text-align: center;
    }

    .mGlobalHeader .menu .mobileContact {
        display: inline-block;
    }
}
