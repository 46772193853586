.mCheckbox {
    display: block;
    font-size: 19.2px;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 5px;
    position: relative;
}

.mCheckbox input {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.mCheckbox .checkboxText {
    display: block;
    padding-left: 25px;
}

.mCheckbox input:focus + .checkboxText {
    outline: 1px dotted;
}

.mCheckbox .checkboxText:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color-denim);
}

.mCheckbox input:checked + .checkboxText:before {
    background: inline(images/checkmark.svg) center center no-repeat;
}

/*
.mCheckbox input:checked + .checkboxText:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: green;
}
*/

.mCheckbox.radio .checkboxText:before {
    padding: 3px;
    border-radius: 50%;
}

.mCheckbox.radio input:checked + .checkboxText:before {
    background: black;
    background-clip: content-box;
}
