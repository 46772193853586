.mWallProperties {
    margin: 20px 0;
}

.mWallProperties dl {
    margin-top: 0;
    /* Have to establish block formatting context for the floats to avoid
     * introducing clearance due to the floating .explanations
     */
    display: inline-block;
    width: 100%;
}

.mWallProperties dt {
    float: left;
    clear: left;
}

.mWallProperties dt:after {
    content: ": ";
    white-space: pre;
}

.mWallProperties dd {
    float: left;
    margin-left: 0;
}
