/* Contains HelpButton, Range, Checkbox, WallType */
.mWallParameters .section,
.mWallParameters .range {
    margin: 40px 0;
}

.mWallParameters .boardTypes {
    border: 1px solid var(--color-cod-gray);
    border-style: solid none;
    padding: 60px 0;
    margin: 60px 0;
}

.mWallParameters .boardTypes fieldset {
    font-size: 0;
    letter-spacing: 0;
}

.mWallParameters .helpText {
    margin-top: 20px;
    font-size: 19.2px;
}

.mWallParameters .boardTypes legend,
.mWallParameters .boardTypes .helpText,
.mWallParameters .studRadios legend {
    margin: 0 0 20px;
}

.mWallParameters .boardTypes legend {
    width: 100%;
    text-align: center;
}

.mWallParameters .boardType {
    font-size: 19.2px;
    letter-spacing: 0.4px;
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin: 20px 0;
    padding: 0 10px;
    text-align: center;
}

.mWallParameters .boardType label {
    display: block;
}

.mWallParameters .boardType .labelText {
    display: block;
    position: relative;
    padding: 51px 0 5px;
    background-image: inline(images/board_types/std.svg);
    background-size: 40px 40px;
    background-position: center 3px;
    background-repeat: no-repeat;
    color: var(--color-std);
}

.mWallParameters .boardType input:checked + .labelText {
    background-image: inline(images/board_types/std_selected.svg);
    background-size: 46px 46px;
    background-position: center top;
}

.mWallParameters .boardType.aq .labelText {
    background-image: inline(images/board_types/aq.svg);
    color: var(--color-aq);
}

.mWallParameters .boardType.aq input:checked + .labelText {
    background-image: inline(images/board_types/aq_selected.svg);
}

.mWallParameters .boardType.brn .labelText {
    background-image: inline(images/board_types/brn.svg);
    color: var(--color-brn);
}

.mWallParameters .boardType.brn input:checked + .labelText {
    background-image: inline(images/board_types/brn_selected.svg);
}

.mWallParameters .boardType.hb .labelText {
    background-image: inline(images/board_types/hb.svg);
    color: var(--color-hb);
}

.mWallParameters .boardType.hb input:checked + .labelText {
    background-image: inline(images/board_types/hb_selected.svg);
}

.mWallParameters .boardType.hrd .labelText {
    background-image: inline(images/board_types/hrd.svg);
    color: var(--color-hrd);
}

.mWallParameters .boardType.hrd input:checked + .labelText {
    background-image: inline(images/board_types/hrd_selected.svg);
}

.mWallParameters .boardType.lb .labelText {
    background-image: inline(images/board_types/lb.svg);
    color: var(--color-lb);
}

.mWallParameters .boardType.lb input:checked + .labelText {
    background-image: inline(images/board_types/lb_selected.svg);
}

.mWallParameters .boardType.sb .labelText {
    background-image: inline(images/board_types/sb.svg);
    color: var(--color-sb);
}

.mWallParameters .boardType.sb input:checked + .labelText {
    background-image: inline(images/board_types/sb_selected.svg);
}

.mWallParameters .boardType.ub .labelText {
    background-image: inline(images/board_types/ub.svg);
    color: var(--color-ub);
}

.mWallParameters .boardType.ub input:checked + .labelText {
    background-image: inline(images/board_types/ub_selected.svg);
}

.mWallParameters .boardType input {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.mWallParameters .boardType input:focus + .labelText {
    outline: 1px dotted;
}

.mWallParameters .boardType .description {
    display: block;
    font-weight: 700;
    color: var(--color-cod-gray);
}

.mWallParameters .boardType .readMoreTrigger {
    background: transparent;
}

.mWallParameters .boardType .readMoreTrigger:hover,
.mWallParameters .boardType .readMoreTrigger:focus {
    text-decoration: underline;
}

.mWallParameters .boardType .readMoreTrigger:after {
    content: ">";
}

.mWallParameters .boardType .readMoreText {
    margin-top: 10px;
}

.mWallParameters .boardTypes legend,
.mWallParameters .selectLabel,
.mWallParameters .rangeLabel,
.mWallParameters .studRadios legend {
    text-transform: none;
    font-size: 25.2px;
    line-height: 31.2px;
    font-weight: 700;
    letter-spacing: 0.025em;
}

.mWallParameters .selectLabel {
    margin-right: 5px;
}

.mWallParameters select {
    font-size: 21.6px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.mWallParameters .parameter.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.mWallParameters .classIrrelevant {
    margin-top: 10px;
}

.mWallParameters .rangeLabel {
    display: block;
    margin-bottom: 10px;
}

.mWallParameters .studRadio {
    display: inline-block;
    margin-right: 50px;
}

.mWallParameters .submits {
    margin: 60px 0 0;
    border-top: 1px solid var(--color-cod-gray);
    padding-top: 40px;
    padding-bottom: 120px;
    text-align: center;
}

.mWallParameters .submits h2 {
    font-size: 26.4px;
    line-height: 31.2px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    padding-bottom: 30px;
    background: url(/resources/images/arrow_down.svg) center bottom / 16px 8px no-repeat;
}

.mWallParameters .submits .section {
    display: inline-block;
    width: 100%;
}

.mWallParameters .submits .section.simple {
    max-width: 13em;
    margin-left: 80px;
    margin-right: 80px;
}

@media all and (max-width: 1300px) {
    .mWallParameters .boardTypes,
    .mWallParameters .submits {
        margin-right: 320px;
    }

    .mWallParameters .boardType {
        width: 50%;
    }

    .mWallParameters .submits .section.simple {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media all and (max-width: 980px) {
    .mWallParameters .boardTypes,
    .mWallParameters .submits {
        margin-right: 0;
    }

}

@media all and (max-width: 660px) {
    /* Due to "swipe from edge" issues on e.g. Chrome/iPhone.
     * See https://github.com/wja-no/norgips/issues/35
     */
    .mWallParameters .range {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mWallParameters .submits .section.simple {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
    }
}
