.mProductSelectorList {
    width: 940px;
    padding: 0;
    margin: 0;
    float: left;
}

.mProductSelectorList > li {
    display: block;
    padding-bottom: 30px;
    border-bottom: 2px solid black;
    margin-bottom: 30px;
}

.mProductSelectorList figure {
    margin-left: 0;
    margin-right: 0;
    width: 128px;
}

.mProductSelectorList img {
    max-width: 100%;
}

@media all and (max-width: 1300px) {
    .mProductSelectorList {
        width: 100%;
    }
}

