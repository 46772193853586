/*!
This CSS resource incorporates links to font software which is the valuable
copyrighted property of Monotype and/or its suppliers. You may not attempt to
copy, install, redistribute, convert, modify or reverse engineer this font
software. Please contact Monotype with any questions regarding Web Fonts:
http://www.fonts.com
*/
@font-face {
    font-family:"Brandon Grotesque";
    font-weight: 400;
    src: url("/resources/files/fonts/050663eb-e1cb-4e20-83bc-b6fbb0650136.woff2") format("woff2"), url("/resources/files/fonts/3c904513-944e-4a14-9492-e7bd0f1d77c0.woff") format("woff");
}

@font-face {
    font-family: "Brandon Grotesque";
    font-weight: 400;
    font-style: italic;
    src: url("/resources/files/fonts/40ae7f1f-757a-4df4-90bd-6c1ea77a6cc0.woff2") format("woff2"), url("/resources/files/fonts/0bd54cb1-cfb7-4292-9e99-861508a6df66.woff") format("woff");
}

@font-face {
    font-family: "Brandon Grotesque";
    font-weight: 500;
    src: url("/resources/files/fonts/a771187c-188c-4888-bd4d-3b836a963432.woff2") format("woff2"), url("/resources/files/fonts/1a65ead8-b34f-438d-9735-a9bc57345d3c.woff") format("woff");
}

@font-face {
    font-family: "Brandon Grotesque";
    font-weight: 500;
    font-style: italic;
src: url("/resources/files/fonts/a79637ac-3ae9-4f56-9b28-3604befa38be.woff2") format("woff2"), url("/resources/files/fonts/e3323da0-90be-407d-959c-a8a09096b6df.woff") format("woff");
}

@font-face {
    font-family: "Brandon Grotesque";
    font-weight: 700;
    src: url("/resources/files/fonts/d849a1c9-784e-4774-af52-86b6dc52cae6.woff2") format("woff2"), url("/resources/files/fonts/6a0d5f5e-3ccd-4763-9f8b-e93735b722bc.woff") format("woff");
}
