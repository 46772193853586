.mVideo {
    padding-top: 56.25%; /* 16:9 */
    position: relative;
    margin: 40px 0;
}

.mVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
