.mProductEnvironmentImages {
    padding-bottom: 20px;
    border-bottom: 2px solid black;
    margin-bottom: 40px;
}

.mProductEnvironmentImages ul {
    padding: 0;
    margin: 0 -10px;
    font-size: 0;
    letter-spacing: 0;
}

.mProductEnvironmentImages li {
    display: inline-block;
    width: 140px;
    margin: 0 10px 20px;
}

.mProductEnvironmentImages figure {
    padding: 0;
    margin: 0;
}

.mProductEnvironmentImages img {
    max-width: 100%;
    vertical-align: top;
}
