/* This re-implements some of the captivate.fm inline styles because
 * HTMLPurifier doesn't support all the properties in its allowlist.
 */
.mPodcast > div {
    border-radius: 6px;
    overflow: hidden;
}

.mPodcast iframe {
    border: none;
}
