.mAttachments {
    padding: 0;
    list-style: none;
}

.mAttachments li {
    margin: 10px 0;
}

.mAttachments a:before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: bottom;
    margin-right: 5px;
    background: inline(images/download_pdf.svg) no-repeat center left;
}

.mAttachments .dwg:before {
    background-image: inline(images/download_dwg.svg);
}
