/* Contains NavAction */
.mHighlightedContent {
    height: 680px;
    position: relative;
    background: no-repeat center center black;
    background-size: cover;
    z-index: 0;
}

.mTopContent > .mHighlightedContent {
    clear: left;
}

.mTopContent > .mHighlightedContent.halfwidth {
    float: left;
    width: 50%;
    clear: none;
}

.mHighlightedContent:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-limed-spruce);
    opacity: 0.8;
}

.mHighlightedContent[data-overlay]:before {
    opacity: 0;
    transition: opacity 0.5s;
}

.mHighlightedContent[data-overlay=on]:before {
    opacity: 0.8;
}

.mHighlightedContent .text {
    width: 420px;
    color: white;
    position: absolute;
    top: 280px;
    right: 50%;
    margin-right: -210px;
    max-height: 400px;
    overflow: auto;
    padding: 40px 15px 30px;
    text-align: center;
}

.mHighlightedContent[data-overlay] .text {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 0.5s, transform 0.5s;
}

.mHighlightedContent[data-overlay=on] .text {
    opacity: 1;
    transform: translateY(0);
}

.mTopContent > .mHighlightedContent.halfwidth .text {
    width: auto;
    top: auto;
    bottom: 20px;
    left: 20px;
    right: 20px;
    max-width: 620px;
    margin: 0 auto;
    padding: 15px;
}

.mHighlightedContent h2 {
    font-weight: 500;
    margin: 14px 0;
}

.mHighlightedContent .abstract {
    letter-spacing: 1px;
}

.mHighlightedContent .slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.mHighlightedContent .slides > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center center black;
    background-size: cover;
    z-index: -1;
}

.mHighlightedContent .slides.sliding > div {
    transition: left 0.6s ease-in-out;
}

.mHighlightedContent .slides > .previous {
    left: -100%;
    z-index: 0;
}

.mHighlightedContent .slides > .current {
    z-index: 0;
}

.mHighlightedContent .slides > .next {
    left: 100%;
}

.mHighlightedContent .carouselButton {
    background: black;
    border: none;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 8px;
    z-index: 1;
}

.mHighlightedContent .carouselButton:focus {
    outline: 1px dotted black;
    outline-offset: 1px;
}

.mHighlightedContent .carouselButton > img {
    vertical-align: top;
}

@media all and (max-width: 980px) {
    .mTopContent > .mHighlightedContent.halfwidth {
        float: none;
        width: auto;
    }
}

@media all and (max-width: 660px) {
    .mHighlightedContent {
        height: auto;
        padding-top: 45.3%;
        background-size: contain;
        background-position: top center;
    }

    .mTopContent > .mHighlightedContent.halfwidth {
        background-size: cover;
        background-position: center -80px;
    }

    .mHighlightedContent:before {
        display: none;
    }

    .mHighlightedContent .slides {
        height: 0%;
        padding-top: 45.3%;
    }

    .mHighlightedContent .text {
        width: auto;
        position: static;
        max-height: none;
        margin-right: 0;
        background: var(--color-limed-spruce);
    }

    .mHighlightedContent[data-overlay] .text {
        opacity: 1;
        transform: translateY(0);
    }

    .mTopContent > .mHighlightedContent.halfwidth .text {
        max-width: none;
    }
}
