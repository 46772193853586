.mBlockquote {
    margin: 28px 0;
    font-weight: 500;
    font-size: 26.4px;
    line-height: 31.2px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 30px 0;
    border-width: 2px;
    border-style: solid none;
}
