/* Contains LinkList */
.mProductIntro {
    padding-bottom: 40px;
    border-bottom: 2px solid black;
    margin-bottom: 40px;
}

.mProductIntro:after {
    content: "";
    display: block;
    clear: left;
}

.mProductIntro .description {
    float: left;
    width: 620px;
    margin-bottom: 40px;
}

.mProductIntro figure {
    margin: 0;
    float: left;
    width: 300px;
    clear: left;
}

.mProductIntro figure > img {
    max-width: 100%;
}

.mProductIntro .usage {
    width: 300px;
    float: left;
    clear: left;
}

.mProductIntro figure + .usage {
    margin-left: 20px;
    clear: none;
}

.mProductIntro .usage ul {
    margin: 0;
    padding-left: 0.75em;
    list-style: none;
}

.mProductIntro .usage li:before {
    content: "\2022";
    display: inline-block;
    width: 0.75em;
    margin-left: -0.75em;
}

.mProductIntro .documentation,
.mProductIntro .assembly {
    margin-left: 640px;
}

.mProductIntro .documentation {
    margin-bottom: 50px;
}

.mProductIntro .documentationNote {
    margin: 14px 0 0;
}

@media all and (max-width: 980px) {
    .mProductIntro .documentation,
    .mProductIntro .assembly {
        margin-left: 0;
        float: left;
        width: 300px;
        clear: left;
        margin-top: 40px;
    }

    .mProductIntro .documentation {
        margin-right: 20px;
        margin-bottom: 0;
    }

    .mProductIntro .documentation + .assembly {
        clear: none;
    }
}

@media all and (max-width: 660px) {
    .mProductIntro .description {
        width: auto;
    }

    .mProductIntro figure {
        float: none;
    }

    .mProductIntro .usage {
        width: auto;
        margin-top: 14px;
    }

    .mProductIntro figure + .usage {
        margin-left: 0;
    }

    .mProductIntro .documentation {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .mProductIntro .documentation + .assembly {
        margin-top: 0;
    }
}
