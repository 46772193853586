.mSystemHeader {
    font-size: 0;
    letter-spacing: 0;
    margin: 50px 0 40px;
}

.mSystemHeader figure {
    display: inline-block;
    vertical-align: middle;
    width: 140px;
    margin: 0 20px 0 0;
}

.mSystemHeader img {
    max-width: 100%;
    vertical-align: top;
}

.mSystemHeader h1 {
    display: inline-block;
}
