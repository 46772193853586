.mBimWalls {
    border-top: 1px solid;
    /* Top margin tries to be equal to mBimFilter legend height, for vertical
     * alignment
     */
    margin: 25px 0 50px;
    padding: 20px 0 0;
}

.mBimWalls li {
    list-style: none;
    margin-bottom: 10px;
}

.mBimWalls li:after {
    content: "";
    display: block;
    clear: right;
}

.mBimWalls a {
    color: inherit;
}

.mBimWalls .wallName {
    font-weight: bold;
}

.mBimWalls .download {
    font-weight: 500;
    float: right;
    text-decoration: none;
}

.mBimWalls .download:focus,
.mBimWalls .download:hover {
    text-decoration: underline;
}

.mBimWalls .download:after {
    content: url(/resources/images/download.svg);
    vertical-align: -2px;
}

@media all and (max-width: 660px) {
    .mBimWalls li {
        margin-bottom: 20px;
    }

    .mBimWalls .download {
        float: none;
        display: block;
    }
}
