.mWallSelectorHeader {
    padding: 100px 0 0;
    margin: 0 -10px;
}

.mWallSelectorHeader:after {
    content: "";
    display: block;
    clear: left;
}

.mWallSelectorHeader > h1,
.mWallSelectorHeader > .abstract {
    float: left;
    width: 50%;
    padding: 0 10px;
}

.mWallSelectorHeader > h1 {
    float: left;
    margin: 0;
    font-weight: 700;
}

.mWallSelectorHeader > h1 > span {
    display: inline-block;
    padding: 15px 0 5px 100px;
    background-image: inline(images/wall_selector_logo.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 70px;
    margin-left: 220px;
}

.mWallSelectorHeader > .abstract {
    font-size: 18px;
    font-weight: 500;
    max-width: 30em;
}

@media all and (max-width: 1300px) {
    .mWallSelectorHeader {
        margin: 0 320px 0 0;
    }

    .mWallSelectorHeader > h1,
    .mWallSelectorHeader > .abstract {
        float: none;
        width: auto;
        padding: 0;
    }

    .mWallSelectorHeader > h1 {
        margin-bottom: 20px;
    }

    .mWallSelectorHeader > h1 > span {
        margin-left: 0;
    }
}

@media all and (max-width: 980px) {
    .mWallSelectorHeader {
        margin: 0;
    }

    .mWallSelectorHeader > h1 > span {
        padding: 30px 0 10px 100px;
    }
}

@media all and (max-width: 660px) {
    .mWallSelectorHeader > h1 > span {
        padding: 5px 0 5px 50px;
        background-size: 40px;
    }
}
