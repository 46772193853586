.mWallType {
    display: inline-block;
    background: transparent;
    font-weight: 500;
    font-size: 25.2px;
    line-height: 31.2px;
    letter-spacing: 0.025em;
    text-align: center;
    position: relative;
}

.mWallType:focus {
    outline: 1px dotted;
}

.mWallType > img {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
    width: 200px;
    height: 200px;
    border-radius: 100px;
}

.mWallType > img + img {
    margin-left: 15px;
}

.mWallType > .buttonText,
.mWallType .wallName {
    display: block;
}

button.mWallType > .buttonText {
    text-decoration: underline;
}

.mWallType > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    padding-top: 50px;
}

.mWallType .tryAgain {
    text-decoration: underline;
}

@media all and (max-width: 660px) {
    .mWallType > img {
        margin-top: 10px;
    }

    .mWallType > img + img {
        margin-left: 0;
    }
}
