.mMonsterMenu {
    color: black;
    background: var(--color-ebb);
    padding: 40px 20px;
    background-size: cover;
}

.mMonsterMenu.products {
    background: var(--color-envy);
}

.mMonsterMenu h2 {
    font-weight: 500;
    margin: 10px 0;
}

.mMonsterMenu ul {
    margin: 0 0 20px;
    padding: 0;
}

.mMonsterMenu ul.outer {
    -moz-column-width: 260px;
    -webkit-column-width: 260px;
    column-width: 260px;
    -moz-column-gap: 60px;
    -webkit-column-gap: 60px;
    column-gap: 60px;
    margin-bottom: 0;
}

.mMonsterMenu li {
    display: block;
    font-weight: bold;
}

.mMonsterMenu ul.outer > li {
    display: inline-block;
    width: 100%;
}

.mMonsterMenu a {
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.mMonsterMenu a:hover,
.mMonsterMenu a:focus {
    text-decoration: underline;
}
