.mFilteredProductData {
    padding-bottom: 40px;
    border-bottom: 2px solid black;
    margin-bottom: 40px;
}

.mFilteredProductData .field {
    width: 300px;
    float: left;
    text-align: center;
    margin-bottom: 20px;
}

.mFilteredProductData .field + .field {
    margin-left: 20px;
}

.mFilteredProductData .buttons {
    clear: left;
    padding: 20px 0;
}

.mFilteredProductData button {
    text-transform: uppercase;
    color: var(--color-scorpion);
    min-width: 146px;
    letter-spacing: 1px;
    padding-left: 27px;
    background: left center no-repeat var(--color-alto);
}

.mFilteredProductData .apply {
    margin-right: 8px;
    background-image: inline(images/show_products.svg);
}

.mFilteredProductData .reset {
    background-image: inline(images/reset.svg);
}

.mFilteredProductData select {
    width: 100%;
    text-align: center;
}

.mFilteredProductData .tableWrapper {
    overflow: auto;
    margin: 20px 0;
}

/*
Would have used display:table-row; visibility:collapse; on [hidden] table rows
here, but WebKit doesn't support it.
*/

.mFilteredProductData th,
.mFilteredProductData td {
    padding-left: 20px;
    padding-right: 20px;
}

.mFilteredProductData thead th {
    background-color: white;
    color: black;
    font-weight: 400;
    letter-spacing: 1px;
}

.mFilteredProductData td {
    font-size: 16.8px;
}

@media all and (max-width: 980px) {
    .mFilteredProductData .field {
        float: none;
    }

    .mFilteredProductData .field + .field {
        margin-left: 0;
    }
}
