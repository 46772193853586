@media print {
    * {
        color: black !important;
        background: white !important;
    }

    table {
        border-collapse: collapse !important;
    }

    td, th {
        border: 1px solid;
    }

    .mGlobalHeader {
        display: none !important;
    }

    .mHighlightedContent {
        height: auto !important;
        border: 1px solid;
        padding-top: 0 !important;
    }

    .mHighlightedContent .text {
        position: static !important;
    }
}
