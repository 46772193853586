.gridWrapper {
    max-width: 1260px;
    margin: 0 auto;
}

.gridWrapper:after {
    content: "";
    display: block;
    clear: left;
}

.gridLeft {
    float: left;
    width: 300px;
    margin-right: 20px;
    padding-top: 40px;
}

.gridMain {
    padding-top: 40px;
    margin-left: 320px;
}

.gridLeft + .gridMain {
    float: left;
    width: 600px;
    margin-left: 0;
}

.genericPage .gridMain {
    padding-bottom: 120px;
    margin-right: 320px;
}

.genericPage .gridMain.noSidebars {
    margin-left: 160px;
    margin-right: 160px;
}

.productPage .gridMain {
    padding-bottom: 40px;
}

.systemPage .gridMain,
.searchPage .gridMain,
.gridVideoWrapper {
    margin-right: 320px;
}

.gridMainBlock {
    margin-left: 320px;
    margin-right: 320px;
}

.bimWallPage .gridWrapper,
.bimWallPage .gridMainBlock {
    padding-top: 40px;
}

.bimWallPage .gridLeft,
.bimWallPage .gridMain {
    padding-top: 0;
}

@media all and (max-width: 1300px) {
    .gridWrapper {
        max-width: 940px;
    }

    .gridMain,
    .genericPage .gridMain.noSidebars,
    .gridMainBlock {
        margin-left: 0;
    }

    .bimWallPage .gridMainBlock {
        margin-left: 320px;
        margin-right: 0;
    }
}

@media all and (max-width: 980px) {
    .gridWrapper {
        max-width: 620px;
    }

    .gridLeft,
    .gridLeft + .gridMain {
        float: none;
        width: auto;
        margin-right: 0;
    }

    .genericPage .gridMain,
    .genericPage .gridMain.noSidebars,
    .systemPage .gridMain,
    .searchPage .gridMain,
    .gridMainBlock,
    .gridVideoWrapper {
        margin-right: 0;
    }

    .bimWallPage .gridMainBlock {
        margin-left: 0;
    }

    .bimWallPage .gridLeft:after {
        content: "";
        display: block;
        clear: left;
    }
}

@media all and (max-width: 660px) {
    .gridWrapper {
        max-width: none;
        margin: 0 10px;
    }
}
