.mWallCalculatorHeader {
    padding-bottom: 10px;
    border-bottom: 2px solid;
}

.mWallCalculatorHeader .abstract {
    font-size: 26.4px;
    line-height: 31.2px;
    letter-spacing: 1px;
}

.mWallCalculatorHeader > h1 > span {
    display: inline-block;
    padding: 15px 0 5px 100px;
    background-image: inline(images/wall_calculator_logo.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 70px;
}
