.mIllustration {
    margin: 40px 0;
}

.mIllustration img {
    max-width: 100%;
}

.mIllustration.smallImage {
    margin: 40px -10px;
    display: flex;
    align-items: flex-end;
}

.mIllustration figcaption {
    margin: 5px 0;
}

.mIllustration.smallImage > a,
.mIllustration.smallImage > img,
.mIllustration.smallImage figcaption {
    width: 33.33%;
    padding: 0 10px;
    margin: 0;
}

.mIllustration figcaption p:last-child {
    margin-bottom: 0;
}

@media all and (max-width: 660px) {
    .mIllustration.smallImage {
        display: block;
    }

    .mIllustration.smallImage > a,
    .mIllustration.smallImage > img,
    .mIllustration.smallImage figcaption {
        width: auto;
    }
}
