.mGalleryIllustrations {
    margin: 0 -10px;
}

.mGalleryIllustrations:after {
    content: "";
    display: block;
    clear: left;
}

.mGalleryIllustrations figure {
    display: table;
    margin: 0 10px 20px;
}

.mGalleryIllustrations .captionLeft {
    margin-left: auto; /* Right-align the image */
}

.mGalleryIllustrations .portrait {
    float: left;
}

.mGalleryIllustrations .portrait.captionLeft {
    float: none;
}

.mGalleryIllustrations figcaption {
    display: table-cell;
    width: 320px;
    vertical-align: middle;
    padding-right: 20px;
}

.mGalleryIllustrations img + figcaption {
    padding-right: 0;
    padding-left: 20px;
}

.mGalleryIllustrations h2 {
    margin: 0;
    font-size: 19.2px;
}

.mGalleryIllustrations img {
    vertical-align: top;
}

@media all and (max-width: 1300px) {
    .mGalleryIllustrations > .landscapeWithCaption > img {
        width: 620px;
    }

    .mGalleryIllustrations > .landscapeWithoutCaption > img {
        width: 940px;
    }
}

@media all and (max-width: 980px) {
    .mGalleryIllustrations .captionLeft {
        margin-left: 10px;
    }

    .mGalleryIllustrations > .landscapeWithCaption {
        display: block;
    }

    .mGalleryIllustrations > .landscapeWithCaption > figcaption {
        display: block;
        width: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .mGalleryIllustrations > .landscapeWithCaption > img,
    .mGalleryIllustrations > .landscapeWithoutCaption > img {
        width: 100%;
    }

    .mGalleryIllustrations > .portrait > img {
        width: 300px;
    }
}

@media all and (max-width: 660px) {
    .mGalleryIllustrations > .portrait {
        display: block;
    }

    .mGalleryIllustrations > .portrait > figcaption {
        display: block;
        width: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .mGalleryIllustrations > .portrait > img {
        width: 100%;
    }
}
