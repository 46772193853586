.mEmployeeList {
    padding: 0;
    list-style: none;
    margin-bottom: 60px;
}

.mEmployeeList article {
    padding: 20px 0;
    border-bottom: 2px solid black;
}

.mEmployeeList article:after {
    content: "";
    display: block;
    clear: left;
}

.mEmployeeList figure {
    margin: 0 20px 0 0;
    float: left;
}

.mEmployeeList figure ~ * {
    margin-left: 127px;
}

.mEmployeeList p {
    margin-bottom: 0;
}

.mEmployeeList .contactInfo {
    margin-top: 14px;
    margin-bottom: 14px;
}

.mEmployeeList a {
    word-wrap: break-word;
}
