.mGalleryList h1 {
    margin: 40px 0;
}

.mGalleryList ul {
    list-style: none;
    padding: 0;
    margin: 0 -10px;
    font-size: 0;
    letter-spacing: 0;
}

.mGalleryList li {
    font-size: 19.2px;
    letter-spacing: 0.4px;
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin: 0 10px 100px;
}

.mGalleryList figure {
    margin: 0;
}

.mGalleryList a {
    color: inherit;
    font-weight: bold;
    text-transform: uppercase;
}

@media all and (max-width: 660px) {
    .mGalleryList li {
        display: block;
        width: auto;
    }

    .mGalleryList img {
        max-width: 100%;
    }
}
