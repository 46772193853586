.mHighlightedLink a {
    display: inline-block;
    background-repeat: no-repeat;
    padding: 5px 0;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.mHighlightedLink a:focus,
.mHighlightedLink a:hover {
    text-decoration: underline;
}

.mHighlightedLink img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    border-radius: 15px;
    margin-right: 10px;
}
