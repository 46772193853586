.mFormFieldGrid {
    margin-bottom: 14px;
}

.mFormFieldGrid th,
.mFormFieldGrid td {
    background-color: transparent;
    color: inherit;
    font-weight: 700;
    text-transform: none;
    padding: 0 40px 14px 0;
}

.mFormFieldGrid thead th {
    font-size: 16.8px;
}

.mFormFieldGrid tbody th {
    text-align: left;
    min-width: 12em;
}

.mFormFieldGrid input {
    width: 5em;
}

/* Variant for when a two-dimensional grid of labels/headings aren't actually
 * needed but where we want alignment to be consistent with a full table-based
 * grid on the same page/in the same form
 */
.mFormFieldGrid.singleDimension {
    font-weight: 700;
}

.mFormFieldGrid.singleDimension label {
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    min-width: 12em;
}

@media all and (max-width: 660px) {
    .mFormFieldGrid table {
        display: block;
    }

    .mFormFieldGrid tr {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .mFormFieldGrid th,
    .mFormFieldGrid td {
        display: block;
        width: 50%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .mFormFieldGrid th:first-child {
        width: 100%;
    }

    .mFormFieldGrid tbody th {
        text-align: left;
        padding-bottom: 5px;
    }

    .mFormFieldGrid input {
        width: 100%;
    }
}
