.mSearchResults {
    padding: 0;
    margin: 0;
}

.mSearchResults > li {
    display: block;
    border-top: 2px solid black;
    padding: 15px 0;
}

.mSearchResults > li:first-child {
    border-top-style: none;
}

.mSearchResults a {
    color: black;
}
